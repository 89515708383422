<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='statuses'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"district"'

    >
<!--      <template #custom_actions="{ item, generalActions }">-->
<!--        <b-link-->
<!--          v-b-tooltip.hover.v-success-->
<!--          :title="$t('Details')"-->
<!--          class="ml-1"-->
<!--          :to="{name: 'view-district', params: { resourceId: item.id }}"-->
<!--        >-->
<!--          <feather-icon-->
<!--            icon="EyeIcon"-->
<!--            size="16"-->
<!--            class="align-middle text-body"-->
<!--          />-->
<!--        </b-link>-->
<!--      </template>-->
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  computed: {
    statuses() {
      return [
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
      ]
    },
  },

  data() {
    return {
      resourceName: 'districts',
      singularName: 'district',
      searchFields: {
        name: null,
        status: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
